<template>
  <pz-form-flujo
    v-model="formulario1['captcha']"
    :available-captcha="extraData.withCaptcha"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.form1_persona.UY.title') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      label-position="top"
      :model="formulario1"
      status-icon
      :rules="rules_formulario1"
    >
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="estudios"
            :label="$t('forms_flujo.form1_persona.SP.studies')"
            :error="errors.get('estudios')"
          >
            <el-select
              v-model="formulario1.estudios"
              clearable
              :placeholder="$t('forms_flujo.form1_persona.SP.studies')"
            >
              <el-option
                v-for="item in studies"
                :key="item.id"
                :label="item.estudio"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="info_extra.transporte"
            :label="$t('forms_flujo.form1_persona.UY.medio_transporte')"
            :error="errors.get('info_extra.transporte')"
          >
            <el-select
              ref="info_extra.transporte"
              v-model="formulario1.info_extra.transporte"
              clearable
              :placeholder="$t('forms_flujo.form1_persona.UY.medio_transporte')"
            >
              <el-option
                v-for="item in medio_transporte"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                :placeholder="$t('forms_flujo.form1_persona.UY.medio_transporte')"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 24}"
          :lg="{span: 24}"
          :md="{span: 24}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >

          <el-form-item
            prop="info_extra.depende_deti"
            :label="$t('forms_flujo.form1_persona.UY.depende_deti')"
            :error="errors.get('info_extra.depende_deti')"
          >
            <el-checkbox-group v-model="formulario1.info_extra.depende_deti">
              <el-checkbox v-for="depende in data_depende" :label="depende" :key="depende" border ></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-col>

      </el-row>

      <hr class="pz-dotted">
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="tipo_vivienda"
            :label="$t('forms_flujo.form2_persona.SP.property_type')"
            :error="errors.get('tipo_vivienda')"
          >
            <el-select
              v-model="formulario1.tipo_vivienda"
              clearable
              :placeholder="$t('forms_flujo.form2_persona.SP.property_type')"
            >
              <el-option
                v-for="item in properties"
                :key="item.id"
                :label="item.tipo"
                :value="item.id"
                :placeholder="$t('forms_flujo.form2_persona.property_type')"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <pz-direction
        v-model="formulario1.direccion"
      />
    </el-form>
  </pz-form-flujo>
</template>
<script>

import {required} from "@/commons/utils/Rules";
import {getEstudios, getTipoViviendas} from "@/routes/api/resources";
import 'element-ui/lib/theme-chalk/display.css'
import PzBaseForm1 from "@/components/Flujo/Formularios/Formularios1/baseForm1";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzDirection from "@/commons/forms/Fields/PzDirection";

export default {
    name: 'PzPersonForm1Impacta2',
    components: { PzDirection, PzFormFlujo},
    mixins: [PzBaseForm1],
    data() {

        return {
            properties: [],
            studies: [],
            data_depende: [
               this.$t('forms_flujo.form1_persona.UY.depende.hijos'),
                 this.$t('forms_flujo.form1_persona.UY.depende.pareja'),
               this.$t('forms_flujo.form1_persona.UY.depende.hermanos'),
                this.$t('forms_flujo.form1_persona.UY.depende.notengo'),
                this.$t('forms_flujo.form1_persona.UY.depende.otros')
            ],
            medio_transporte: [
                {id: 'Transporte publico', name: this.$t('forms_flujo.form1_persona.UY.transporte_publico')},
                {id: 'Carro propio', name: this.$t('forms_flujo.form1_persona.UY.carro')},
                {id: 'Moto propia', name: this.$t('forms_flujo.form1_persona.UY.moto')},
                {id: 'Otros', name: this.$t('forms_flujo.form1_persona.UY.otros')}
            ],
            formulario1: {
                estudios:'',
                direccion: {},
                tipo_vivienda: '',
                info_extra: {
                    depende_deti: [],
                    transporte: '',
                }
            },
            rules_formulario1: {
                estudios: [required('')],
                tipo_vivienda: [required('')],
                'info_extra.depende_deti': [required('')],
                'info_extra.transporte': [required('')],
                info_extra: {
                    depende_deti: [required('')],
                    transporte: [required('')]
                },

            }
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.$store.dispatch('loader/up', {trigger: this.$options.name})
            let est = getEstudios().then((res) => {
                this.studies = res.data;
            }).catch(() => {
                this.studies = [];
            });
            let viv = getTipoViviendas().then((res) => {
                this.properties = res.data;
            }).catch(() => {
                this.properties = [];
            });
            return Promise.all([est, viv]).then(() => {
            }).finally(() => {
                this.$store.dispatch('loader/down', {trigger: this.$options.name})
            });
        },
    },


}
</script>

